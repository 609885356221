import { getMetaContent } from "./application.js";

export default class CookieConsent {
  constructor() {
    this.dialog = document.createElement("dialog");
    this.dialog.classList.add("cc-container");
    document.body.append(this.dialog);

    const form = document.createElement("form");
    form.classList.add("flex-row", "flex-gapped", "flex-wrap");
    form.method = "dialog";
    this.dialog.append(form);

    const message = document.createElement("span");
    message.textContent = getMetaContent("ccMessage");
    form.append(message);

    const moreInfo = document.createElement("a");
    moreInfo.href = getMetaContent("ccHref");
    moreInfo.rel = "noopener";
    moreInfo.target = "_blank";
    moreInfo.textContent = getMetaContent("ccLink");
    form.append(moreInfo);

    const dismiss = document.createElement("button");
    dismiss.textContent = getMetaContent("ccDismiss");
    dismiss.addEventListener("click", this.onDismiss);
    form.append(dismiss);
  }

  readonly dialog: HTMLDialogElement;

  showIfNotDismissed() {
    if (window.localStorage.getItem("dismissedCookieConsent") === "true")
      return;

    this.dialog.show();
  }

  protected onDismiss() {
    window.localStorage.setItem("dismissedCookieConsent", "true");
  }
}

new CookieConsent().showIfNotDismissed();
