import type Toast from "@sabre/spark/js/src/components/toast.js";
import type { Options as ToastOptions } from "@sabre/spark/js/src/components/toast.js";
import type ToggleSwitch from "@sabre/spark/js/src/components/toggle-switch.js";
import * as DOMPurify from "dompurify";

const ERROR_MESSAGE_SELECTOR = ".error-message";
const SUCCESS_MESSAGE_SELECTOR = ".success-message";

/** Assert that the given instance is an instance of the given type. */
export function assertType<
  Instance,
  Constructor extends ConstructorOf<Instance>
>(
  element: Instance | null,
  elementType: Constructor
): InstanceType<Constructor> {
  if (element instanceof elementType)
    return element as InstanceType<Constructor>;

  console.error(
    "The element was not of the expected type.",
    element,
    elementType
  );
  throw new Error("The element was not of the expected type.");
}

type ToggleSwitchCallback<E extends Element = Element> = (
  newValue: boolean,
  instance: ToggleSwitch<E>
) => Promise<void>;

/** Create a simple callback for a toggle switch, posting to a URL. */
export function createToggleSwitchCallback<E extends Element = Element>(
  urlCallback: (newValue: boolean, instance: ToggleSwitch<E>) => string
): ToggleSwitchCallback<E> {
  return async (newValue, instance) => {
    try {
      instance.disable();
      const response = await fetch(urlCallback(newValue, instance), {
        headers: getCsrfHeaders(),
        method: "POST"
      });
      if (!response.ok) throw new Error("The response was not OK.");
    } catch (error) {
      console.error("Error on toggling switch.", error);
      if (instance.input) instance.input.checked = !newValue;
    } finally {
      instance.enable();
    }
  };
}

/**
 * Debounce the given callback.
 * This function only executes the passed callback once for subsequent events,
 * at the last event once the timeout has run out.
 *
 * @param timeout - the timeout
 * @param callback - the callback
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce<This, Callback extends (...args: any[]) => void>(
  this: This,
  timeout: number,
  callback: Callback
): (...args: Parameters<Callback>) => void {
  let timer: number;
  return (...args) => {
    window.clearTimeout(timer);
    timer = window.setTimeout(() => {
      callback.apply(this, args);
    }, timeout);
  };
}

/** Get the context path of the application. */
export function getContextPath(): string {
  return getMetaContent("contextPath");
}

/**
 * Get the current date with the time components set to 0 in UTC.
 *
 * @returns today's date
 */
export function getToday(): Date {
  const now = new Date();
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);
  return now;
}

/**
 * Get the step value for a number input for the given amount of decimal places.
 */
export function getStep(decimalPlaces: number): number {
  return 10 ** -decimalPlaces;
}

/**
 * Get the content of a meta tag by name.
 *
 * @param name - the name of the meta tag
 * @returns the content of the meta tag
 * @throws when the meta tag is not found
 */
export function getMetaContent(name: string): string {
  const meta = document.querySelector(`meta[name="${name}"]`);
  if (meta instanceof HTMLMetaElement) {
    return meta.content;
  } else {
    throw new Error(`The meta tag with name "${name}" was not found!`);
  }
}

/**
 * Get an object that represents the CSRF header.
 *
 * @throws when either of the meta tags was not found
 */
export function getCsrfHeaders(): Record<string, string> {
  let header;
  let csrf;
  try {
    header = getMetaContent("_csrf_header");
    csrf = getMetaContent("_csrf");
  } catch (e) {
    throw new Error(`The CSRF header meta tags were not found! ${e}`);
  }
  return { [header]: csrf };
}

/**
 * Open the given toast with the given options and the recommended duration.
 * The recommended duration is 3 seconds plus 60ms per each character in the
 * toast content. Only the title and details are considered for character
 * length.
 * @see http://sabrespark.com/ui/toast-notifications.html#time-considerations
 */
export function openToastWithRecommendedDuration(
  toast: Toast,
  options: Omit<ToastOptions, "duration">
) {
  const charLength =
    (options.title?.length ?? 0) + (options.details?.length ?? 0);

  toast.open({
    ...options,
    duration: 3 + charLength * 0.06
  });
}

/**
 * Set the error message on a page and clear the success message.
 *
 * @param message - the message to set
 */
export function setErrorMessage(message: string): void {
  clearMessages();
  const errorMessageElement = document.querySelector(ERROR_MESSAGE_SELECTOR);
  if (errorMessageElement) errorMessageElement.textContent = message;
}

/**
 * Set the success message on a page and clear the error message.
 *
 * @param message - the message to set
 */
export function setSuccessMessage(message: string): void {
  clearMessages();
  const successMessageElement = document.querySelector(
    SUCCESS_MESSAGE_SELECTOR
  );
  if (successMessageElement) successMessageElement.textContent = message;
}

/**
 * Clear the messages on a page.
 */
export function clearMessages(): void {
  const errorMessageElement = document.querySelector(ERROR_MESSAGE_SELECTOR);
  if (errorMessageElement) errorMessageElement.textContent = "";
  const successMessageElement = document.querySelector(
    SUCCESS_MESSAGE_SELECTOR
  );
  if (successMessageElement) successMessageElement.textContent = "";
}

/**
 * Decorate invalid fields with the "error-border" class.
 *
 * @param invalidFields - a mapping of invalid field names to meta content booleans
 */
export function decorateInvalidFields(
  invalidFields: Record<string, string>
): void {
  for (const id in invalidFields) {
    if (getMetaContent(invalidFields[id] ?? "") === "true") {
      document.getElementById(id)?.classList.add("error-border");
    }
  }
}

/** A convenience function to convert path notation to kebab case IDs. */
export function pathToId(path: string): string {
  return path.replace(".", "-");
}

/**
 * Set the first part of the given ID (split by ".") as the name of the element
 * selected with the given ID. This is mainly used for Spring form tags to give
 * them a different name than the path. If the selected element is not an
 * HTMLInputElement or HTMLSelectElement, this does nothing.
 *
 * @param id - the ID of the element to add the name to
 */
export function setIdBaseAsName(id: string) {
  const element = document.getElementById(id);
  if (
    element instanceof HTMLInputElement ||
    element instanceof HTMLSelectElement
  ) {
    element.name = id.split(".")[0] ?? "";
  }
}

/**
 * Resize the elements, selected by the passed selector to the maximum of either
 * their current size or the size of their respective background-image, if they
 * have one.
 *
 * @param selector - the selector of the elements to resize
 * @param callback - a callback function to call on resize
 */
export function resizeElementsToBgImage(
  selector: string,
  callback: (element: HTMLElement) => void = () => {
    // NOOP
  }
) {
  document.querySelectorAll(selector).forEach((element) => {
    if (!(element instanceof HTMLElement)) return;

    const backgroundImageCss = window.getComputedStyle(element).backgroundImage;
    const matchArray = extractParamFromCssUrlFuncString(backgroundImageCss);
    if (matchArray === null) {
      return;
    }
    const imageSource = matchArray[2];
    if (!imageSource) return;

    const imageElement = document.createElement("img");
    imageElement.addEventListener("load", (event) => {
      if (!(event.target instanceof HTMLImageElement)) return;

      element.setInnerWidth(Math.max(element.scrollWidth, event.target.width));
      element.setInnerHeight(
        Math.max(element.scrollHeight, event.target.height)
      );
      callback(element);
    });
    imageElement.src = imageSource;
  });
}

/**
 * Extract the parameter of a CSS url() function call string.
 * If the passed string does not match a CSS url() function call exclusively,
 * this returns `null` instead. If the string does match an array is returned
 * just as `RegExp.prototype.exec()` does. In that case, the parameter (without
 * optional quotes) can be fetched using the array element at index 2.
 *
 * @param funcString - the CSS url() function call string
 */
export function extractParamFromCssUrlFuncString(
  funcString: string
): RegExpExecArray | null {
  return /^url\((["']?)(.+)\1\)$/.exec(funcString);
}

/**
 * @param element - the element whose content to copy
 */
export function copyToClipboard(element: HTMLElement): void {
  const copyText =
    element instanceof HTMLInputElement ||
    element instanceof HTMLSelectElement ||
    element instanceof HTMLTextAreaElement
      ? element.value
      : element.textContent;
  if (copyText) navigator.clipboard.writeText(copyText);
}

/**
 * Load the contents of the specified SVG file into the specified HTML element.
 *
 * @param path - the path of the file in the static directory to load
 * @param element - the element to load the file into
 */
export async function loadSvgFileIntoElement(
  path: string,
  element: HTMLElement | null
): Promise<void> {
  if (!element) return;

  const response = await fetch(path);
  if (response.ok) {
    element.innerHTML = DOMPurify.sanitize(await response.text(), {
      USE_PROFILES: { html: true, svg: true },
      FORBID_TAGS: ["style"],
      FORBID_ATTR: ["style"]
    });
  }
}
